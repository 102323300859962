export const setSameWidthBuildingLevelsVisuals = [
  {
    name: "Demo",
    markdown: `![Set same-width building levels GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];

export const setSameWidthBuildingLevels = {
  order: 29,
  name: "Set same-width building levels",
  subcategory: "Properties",
  markdown: `# Set same-width building levels

  {% tablessContainer paddingStyles="px-5" %}
  **Who can use this feature?**

  {% inlineAppIcon iconName="user" /%}{% inlineRouterLink articleId="understand-reputation" sectionName="What reputation affects" %}**Reputable users**{% /inlineRouterLink %}.
  {% /tablessContainer %}

  When a {% inlineRouterLink articleId="add-a-land-covering" %}building{% /inlineRouterLink %} includes multiple {% inlineRouterLink articleId="add-building-levels" %}levels{% /inlineRouterLink %} with {% inlineRouterLink articleId="add-a-floor-area" %}floor areas{% /inlineRouterLink %} that have {% inlineRouterLink %}area measures{% /inlineRouterLink %}, floors will display at different widths in the {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}. Due to {% inlineRouterLink %}remeasurement{% /inlineRouterLink %}, floors can have different reported areas even though the exterior of the (actual) building doesn't change in width. In Tower Hunt, you can force groups of building levels to appear to be the same width regardless of their reported area.

  {% callout type="caution" marginStyles="mb-4" %}
  **Important:** In order to see the relative width of each building level, **every** floor area must have a {% inlineRouterLink articleId="add-floor-area-measures" %}standardized area{% /inlineRouterLink %}.
  {% /callout %}

  {% callout type="note" %}
  **Note:** This feature is purely aesthetic. The underlying floor area measures do not change.
  {% /callout %}

  ## Steps

  {% tablessContainer %}
  1. Visit a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.
  2. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" %}**Select**{% /inlineRouterLink %} a {% inlineRouterLink articleId="visual-features-of-the-property-diagram" sectionName="Land coverings" %}land covering{% /inlineRouterLink %}.
  3. In the {% inlineRouterLink articleId="understand-the-property-diagram-control-panel" %}control panel{% /inlineRouterLink %}, click {% inlinePropertyDiagramIcon name="addVerticalGrouping" /%}.
  4. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" sectionName="Placing new datapoints on the diagram" %}**Select**{% /inlineRouterLink %} one or more {% inlineRouterLink articleId="add-building-levels" %}building levels{% /inlineRouterLink %} to display at the same width.
  5. Click {% inlinePropertyDiagramIcon name="saveEditAction" /%} to save. A (selectable) colored vertical stripe appears next to the affected building levels, which now appear to be the same width.
  {% /tablessContainer %}

  ## Visuals

  {% tabbedContainer tabs=$setSameWidthBuildingLevelsVisuals /%}
  `,
};
